/* eslint-disable no-unused-vars */
export enum enumMovementTypes {
  MONTHLY_INCOME = "monthlyIncome",
  REPERCUSSION = "repercussion",
}

export interface IAmount {
  amount: number;
  currency: string;
}

export interface IFile {
  uuid: string;
  downloadUrl: string;
  viewUrl: string;
  url: string;
  expiresAt: string;
}

export interface IAccountingInAYearItem {
  month: number;
  invoice: IFile | null;
  invoices: IFile[];
  totalAmount: IAmount;
  movements: IAccountingInAYearMovement[];
}
export interface IAccountingInAYearMovement {
  type: enumMovementTypes.MONTHLY_INCOME | enumMovementTypes.REPERCUSSION;
  concept: string;
  amount: IAmount;
  date: string;
}

export interface IAccountingYears {
  items: string[]; // list of years
}

export interface IAccountingInAYear {
  amountOfIncome: IAmount;
  amountOfCharges: IAmount;
  items: IAccountingInAYearItem[];
}
